import { useHistory } from 'react-router-dom';
import aesjs from 'aes-js';
import jwtDecode from 'jwt-decode';
import {
  SET_AUTH_STATUS,
  SET_USER_INFO,
  SET_PARAMETERS_INFO,
  SET_DECODED_TOKEN,
} from '../types/auth.type';
import { SET_LIST_CAR } from '../types/home.type';
import {
  URL_SECURITY, ENCRYPTION_KEY, ENCRYPTION_IV, URL_SECURITY_LETMICERT,
} from '../config/config';
import { assignAuthTokenAndRefreshToken } from '../helpers/authUtils';
import { getTenantByRole } from '../helpers/generalUtils';
import { menuBackOfficeBuilt } from '../helpers/menuConfig';

/**
 * limpia el querystring
 *
 * @method
 * @param {paramsChar} paramsChar
 */
function clearQueryVariable(paramsChar) {
  try {
    // preservar nuevas líneas, etc. - JSON válido
    let paramsCharLocal = paramsChar
      .replace(/\\n/g, '\\n')
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, '\\&')
      .replace(/\\r/g, '\\r')
      .replace(/\\t/g, '\\t')
      .replace(/\\b/g, '\\b')
      .replace(/\\f/g, '\\f');
    // eliminar caracteres JSON no imprimibles y otros no válidos
    paramsCharLocal = paramsCharLocal.replace(/[\000-\031\200-\377]+/g, '');
    return paramsCharLocal;
  } catch (err) {
    console.error('Error limpiando parametros:', err);
  }
}

export const setLogOutAction = (appName, history) => async (dispatch, getState) => {
  try {
    assignAuthTokenAndRefreshToken(false);
    localStorage.removeItem('userName');
    localStorage.removeItem('redirectPage');
    dispatch({ type: SET_USER_INFO, payload: undefined });
    dispatch({ type: SET_LIST_CAR, payload: [] });
    if (appName === 'license') {
      const { auth: { decodedToken } } = getState();
      if (decodedToken) {
        const tenant = getTenantByRole(decodedToken);
        switch (tenant.toUpperCase()) {
          case 'LETMICERT':
            window.location.href = URL_SECURITY_LETMICERT;
            break;
          default:
            window.location.href = URL_SECURITY;
            break;
        }
      } else {
        window.location.href = URL_SECURITY;
      }
    } else {
      history.push('/');
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * decodificar token dentro de un querystring
 *
 * @method
 * @param {queryString} queryString
 */
function decodeTokenData(queryString) {
  try {
    const paramsChar = decodeURIComponent(queryString);
    const aesCbc = new aesjs.ModeOfOperation.cbc(ENCRYPTION_KEY, ENCRYPTION_IV);
    const encryptedtext = aesjs.utils.hex.toBytes(
      new Buffer(paramsChar || '', 'base64').toString('hex'),
    );
    const decryptedBytes = aesCbc.decrypt(encryptedtext);
    const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    const cleanParamsObj = clearQueryVariable(decryptedText);
    const paramsObj = JSON.parse(cleanParamsObj);
    if (paramsObj.token !== undefined && paramsObj.token !== null) {
      const tokens = {
        token: paramsObj.token,
        refreshToken: paramsObj.refreshToken,
      };
      return tokens;
    }
  } catch (err) {
    console.error(err);
  }
}

export const isValidUsertoConfigurations = (decodedTokenJwt, menuConfig) => {
  try {
    const app = decodedTokenJwt.APPS;
    const rol = decodedTokenJwt.LICENSE_ROLE;
    const isArrayRoles = Array.isArray(rol);
    const menu = menuBackOfficeBuilt.find((x) => x.code === menuConfig
                                                        && x.idstate === 0
                                                        && x.disable === false);
    let accessRoleMenu = [];
    if (menu) {
      if (!isArrayRoles) {
        accessRoleMenu = menu.accessRole.filter((x) => x.role === rol);
      } else {
        accessRoleMenu = menu.accessRole.filter((x) => rol.includes(x.role));
      }
    }
    if (accessRoleMenu.length > 0 && app === 'LICENSE') {
      return true;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const authorizeActionsRole = (decodedTokenJwt, menuConfig) => {
  try {
    const rol = decodedTokenJwt.LICENSE_ROLE;
    const menu = menuBackOfficeBuilt.find((x) => x.code === menuConfig
                                                        && x.idstate === 0
                                                        && x.disable === false);
    let authorizeActionRole = null;
    if (menu) {
      const isArrayRoles = Array.isArray(rol);
      if (isArrayRoles) {
        rol.forEach((item) => {
          authorizeActionRole = menu.accessRole.find((x) => x.role === item);
          if (authorizeActionRole) {
            return authorizeActionRole;
          }
        });
      } else {
        authorizeActionRole = menu.accessRole.find((x) => x.role === rol);
      }
      return authorizeActionRole;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getInfoToken = (token) => (dispatch) => {
  const decodedTokenJwt = jwtDecode(token);
  dispatch({ type: SET_DECODED_TOKEN, payload: decodedTokenJwt });
  const userName = decodedTokenJwt.unique_name.toLowerCase();
  localStorage.setItem('userName', userName);
  const userInfo = {
    name: decodedTokenJwt.given_name.toLowerCase(),
    surname: decodedTokenJwt.family_name.toLowerCase(),
    customer_id: decodedTokenJwt.customer_id,
    customer_type: decodedTokenJwt.customer_type,
    customer_value: decodedTokenJwt.customer_value,
  };
  return userInfo;
};

export const getFullToken = (queryString, pathOrigin) => async (dispatch) => {
  try {
    if (queryString) {
      const history = useHistory();
      const tokenAndRefreshToken = decodeTokenData(queryString);
      assignAuthTokenAndRefreshToken(tokenAndRefreshToken);
      const userInfo = dispatch(getInfoToken(tokenAndRefreshToken.token));
      dispatch({ type: SET_USER_INFO, payload: userInfo });
      if (tokenAndRefreshToken.parameters) {
        dispatch({ type: SET_PARAMETERS_INFO, payload: tokenAndRefreshToken.parameters });
      }
      if (pathOrigin.includes('myplan')) {
        history.push('/myplan');
      } else {
        history.push('/');
      }
    }
  } catch (error) {
    console.error(error);
    dispatch(setLogOutAction());
  }
};

export const setInfoToken = (appsUserToken) => (dispatch) => {
  const userInfo = dispatch(getInfoToken(appsUserToken));
  dispatch({ type: SET_USER_INFO, payload: userInfo });
};

/**
 * valida si existe un token de autenticacion de usuario
 * o redirige a login de aplicacion
 *
 * @method
 * @param {appName} appName
 */
export const isAuthenticatedUser = (appName, tenant) => (dispatch, getState) => {
  const tokenUser = localStorage.getItem('appsUserToken');
  dispatch({ type: SET_AUTH_STATUS, payload: !!tokenUser });
  if (!tokenUser) {
    switch (appName) {
      case 'colfactura':
        break;
      case 'firmaya':
        break;
      case 'firmamail':
        break;
      case 'license':
        // eslint-disable-next-line no-case-declarations
        const { auth: { decodedToken } } = getState();
        if (decodedToken) {
          const tenantRole = getTenantByRole(decodedToken);
          switch (tenantRole.toUpperCase()) {
            case 'LETMICERT':
              window.location.href = URL_SECURITY_LETMICERT;
              break;
            default:
              window.location.href = URL_SECURITY;
              break;
          }
        } else if (tenant !== null && tenant !== undefined) {
          switch (tenant.toUpperCase()) {
            case 'LETMICERT':
              window.location.href = URL_SECURITY_LETMICERT;
              break;
            default:
              window.location.href = URL_SECURITY;
              break;
          }
        } else {
          window.location.href = URL_SECURITY;
        }
        break;
      default:
        break;
    }
  }
};

export const test = () => {};

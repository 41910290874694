/* eslint-disable consistent-return */
/* eslint-disable max-len */
import axios from 'axios';
import { API_LICENCIAMIENTO } from '../config/config';
import {
  LOADING, SET_PURCHASE_PARAMETERS, SET_REJECT_TYPES, SET_SERVICE_TYPES,
  GET_FEATURE_TYPES, SET_VALUE_TAB, GET_PARAMETER_COMBO, GENERAL_SET_FILTERS_PAGE,
  GENERAL_SHOW_MESSAGE, SET_PARAMETER_APP,
} from '../types/general.type';
import {
  CLEAR_ERRORS,
} from '../types/purchaseLicense.type';
import { defaultHeaders, paramaterApplication } from '../helpers/generalUtils';
import { addLocalStorageEncript } from '../helpers/storageUtils';

export const getPurchaseOrderParametersAction = () => async (dispatch, getState) => {
  try {
    const docTypes = [];
    const itemsStatesList = [];
    const itemsAddress = [];
    const itemsAddressLetters = [];
    const itemsAddressBis = [];
    const itemsAddressCardinal = [];
    const parameterType = 'ParameterApps';
    const {
      general: { appName, tenant },
    } = getState();

    dispatch({ type: LOADING, payload: true });
    dispatch({ type: CLEAR_ERRORS, payload: {} });
    const config = defaultHeaders();
    Object.assign(config.headers, {
      appName,
      tenant,
      parameterType,
    });
    const applicationParameters = await axios.get(`${API_LICENCIAMIENTO}/license/api/parameter/GetAppParameters`, config);
    const statesCities = await axios.get(`${API_LICENCIAMIENTO}/license/api/parameter/GetListStatesAndCities`, config);
    const documentesTypes = await axios.get(`${API_LICENCIAMIENTO}/license/api/parameter/GetAllDocumentTypes`, config);

    statesCities.data.result.records.forEach((item) => itemsStatesList.push(
      {
        value: item.id,
        text: item.stateName,
      },
    ));
    const statesAndCities = {
      statesList: statesCities.data.result.records,
      itemsStatesList,
    };

    documentesTypes.data.result.records.forEach((item) => docTypes.push(
      {
        value: item.id,
        text: item.documentTypeDesciption,
        documentTypeCode: item.documentTypeCode,
        documentTypeContrats: Array.isArray(item.documentTypeCountries) && item.documentTypeCountries.length > 0
          ? item.documentTypeCountries.map((country) => ({
            countryCode: country.country.countryCode,
            documentTypeCountriesDescription: country.documentTypeCountriesDescription,
            countryId: country.countryId,
          }))
          : [],
      },
    ));

    const application = applicationParameters.data.result.records;

    const assistantAddressTypes = {
      itemsAddress,
      itemsAddressLetters,
      itemsAddressBis,
      itemsAddressCardinal,
    };

    const data = {
      docTypes,
      assistantAddressTypes,
      statesAndCities,
      application,
    };

    addLocalStorageEncript(JSON.stringify(data), 'parametersApp');

    dispatch({
      type: SET_PURCHASE_PARAMETERS,
      payload: data,
    });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.error(error);
  }
};

export const getRejectTypesAction = () => async (dispatch) => {
  try {
    const rejectTypes = [];
    const config = defaultHeaders();
    const rejectsTypesData = await axios.get(`${API_LICENCIAMIENTO}/license/api/parameter/GetAllRejectTypes`, config);
    rejectsTypesData.data.result.records.forEach((item) => rejectTypes.push(
      {
        value: item.id,
        text: item.rejectTypeDescription,
      },
    ));

    dispatch({
      type: SET_REJECT_TYPES,
      payload: rejectTypes,
    });
  } catch (error) {
    console.error(error);
  }
};

export const getServiceTypesAction = () => async (dispatch) => {
  try {
    const serviceTypes = [];
    const config = defaultHeaders();
    const serviceTypesData = await axios.get(`${API_LICENCIAMIENTO}/license/api/parameter/GetAllServiceTypes`, config);
    serviceTypesData.data.result.records.forEach((item) => serviceTypes.push(
      {
        value: item.id,
        label: item.serviceType1,
      },
    ));

    dispatch({
      type: SET_SERVICE_TYPES,
      payload: serviceTypes,
    });
  } catch (error) {
    console.error(error);
  }
};

export const setValueBackOfficeTab = (value) => (dispatch) => {
  dispatch({
    type: SET_VALUE_TAB,
    payload: value,
  });
};

export const getParameterByTypeAction = (parameterType) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const parameterCombo = [];
    const config = defaultHeaders();
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/Parameter/GetParameterByType?parameterCode=${parameterType}`,
      config);
    if (response.status === 200) {
      response.data.result.records.forEach((item) => parameterCombo.push(
        {
          value: item.parameterKey,
          label: item.parameterValue,
        },
      ));

      dispatch({ type: GET_PARAMETER_COMBO, payload: parameterCombo });
      dispatch({ type: LOADING, payload: false });
      return parameterCombo;
    }
  } catch (error) {
    console.error(`Error obteniendo la lista de clientes: ${error.message}`);
  }
};

export const getTokenExternalSourceAction = () => async () => {
  try {
    const config = defaultHeaders();
    const response = await axios.get(
      `${API_LICENCIAMIENTO}/license/api/Auth/GetTokenAdminIbuho`, config,
    );
    if (response.status === 200) {
      localStorage.setItem('tokenAdminIbuho', response.data.result.token);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getFeatureTypesAction = () => async (dispatch) => {
  try {
    const featureTypes = [];
    const config = defaultHeaders();
    const featureTypesData = await axios.get(`${API_LICENCIAMIENTO}/license/api/parameter/GetAllFeatureTypes`, config);
    featureTypesData.data.result.records.forEach((item) => featureTypes.push(
      {
        value: item.id,
        label: item.featureTypeName,
      },
    ));

    dispatch({
      type: GET_FEATURE_TYPES,
      payload: featureTypes,
    });
  } catch (error) {
    console.error(error);
  }
};

export const setFiltersPageAction = (filters) => async (dispatch) => {
  try {
    dispatch({
      type: GENERAL_SET_FILTERS_PAGE,
      payload: filters,
    });
  } catch (error) {
    console.error(error);
  }
};

export const setMessageAlertAction = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GENERAL_SHOW_MESSAGE,
      payload: data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const clearMessageAlertAction = () => async (dispatch) => {
  try {
    dispatch({ type: GENERAL_SHOW_MESSAGE, payload: undefined });
  } catch (error) {
    console.error(error);
  }
};

export const getParmeterConfigAction = (appName, parameterType) => async (dispatch) => {
  const config = defaultHeaders();
  Object.assign(config.headers, {
    appName, parameterType,
  });
  const applicationParameters = await axios.get(`${API_LICENCIAMIENTO}/license/api/parameter/GetAppParameters`, config);
  const application = applicationParameters.data.result.records.parameters;
  const parameterConfig = paramaterApplication(application);
  const urlsFromBack = {
    urlPersonalDataTreatmentPolicy: applicationParameters?.data?.result?.records?.urlPersonalDataTreatmentPolicy,
    urlLandingPage: applicationParameters?.data?.result?.records?.urlLandingPage,
    // urlLandingPage: '',
  };
  parameterConfig.urlsFromBack = urlsFromBack;
  dispatch({
    type: SET_PARAMETER_APP,
    payload: parameterConfig,
  });
};

import GET_BLACK_LIST_EMAIL from '../types/email.type';

const INITIAL_STATE = {
  emailsBlackList: [],
};

/**
 * Reducer for calls of ibuhoo email api
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BLACK_LIST_EMAIL:
      return {
        ...state,
        emailsBlackList: action.payload,
      };
    default:
      return state;
  }
};

import {
  LOADING, GET_PACKAGES, UPDATE_CART, STATUS_MODALCART, STATUS_MODALCART_EXT, SET_PROMO_CODE, GET_MY_PLANS, STATUS_MODALPACKAGES, GET_PACKAGESBYFEATURE,
  SET_LIST_CAR, GET_PACKAGES_FROM_LOCAL, GET_PRICE_BY_LICENSE_TYPE_EMPLOYEE, GET_QUANTITY_LICENSE_EMPLOYEE, GET_PRICE_BY_LICENSE_TYPE_DOCUMENTS, GET_QUANTITY_LICENSE_DOCUMENTS,
  GET_PRICE_BY_LICENSE_TYPE, GET_QUANTITY_LICENSE, HOME_SHOW_CART, CLEAR_ALL_CART, GET_DETAIL_EMPLOYEE, SET_DETAIL_EMPLOYEE, GET_PACKAGE_BY_ENCRYPTED_PARAMETER, GET_PACKAGES_CONTRACTS,
} from '../types/home.type';

const INITIAL_STATE = {
  loading: false,
  packages: {},
  cart: [],
  promoCode: undefined,
  isCartOpen: false,
  isCartOpenExt: false,
  myplan: {},
  isPackageOpen: false,
  packagesMyPlan: {},
  showCart: true,
  isClearAllCart: false,
  detailEmployee: [],
  setDetailEmployee: {},
  getPackageByEncryptedParameter: {},
  isErrorGetPackageByEncripted: false,
  productType: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PACKAGES:
      return {
        ...state,
        packages: {
          offers: action.payload.packagesOffers,
          base: action.payload.packagesBase,
          combo: action.payload.packagesCombo,
          complementary: action.payload.packagesComplementary,
        },
      };
    case UPDATE_CART:
      return {
        ...state,
        cart: action.payload,
      };
    case STATUS_MODALCART:
      return {
        ...state,
        isCartOpen: action.payload,
        isPackageOpen: false,
      };
    case STATUS_MODALCART_EXT:
      return {
        ...state,
        isCartOpenExt: action.payload,
        isPackageOpen: false,
      };
    case SET_PROMO_CODE:
      return {
        ...state,
        promoCode: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_MY_PLANS:
      return {
        ...state,
        myplan: action.payload,
      };
    case STATUS_MODALPACKAGES:
      return {
        ...state,
        isPackageOpen: action.payload,
      };
    case GET_PACKAGESBYFEATURE:
      return {
        ...state,
        packagesMyPlan: {
          complementary: action.payload.packagesComplementary,
        },
      };
    case SET_LIST_CAR:
      return {
        ...state,
        cart: action.payload,
      };
    case GET_PACKAGES_FROM_LOCAL:
      return {
        ...state,
        packages: action.payload,
      };
    case GET_PRICE_BY_LICENSE_TYPE_EMPLOYEE:
      return {
        ...state,
        pricesLicenseEmployee: action.payload,
      };
    case GET_QUANTITY_LICENSE_EMPLOYEE:
      return {
        ...state,
        quantityLicenseEmployee: action.payload,
      };
    case GET_PRICE_BY_LICENSE_TYPE_DOCUMENTS:
      return {
        ...state,
        pricesLicenseDocuments: action.payload,
      };
    case GET_QUANTITY_LICENSE_DOCUMENTS:
      return {
        ...state,
        quantityLicenseDocuments: action.payload,
      };
    case GET_PRICE_BY_LICENSE_TYPE:
      return {
        ...state,
        pricesLicense: action.payload,
      };
    case GET_QUANTITY_LICENSE:
      return {
        ...state,
        quantityLicense: action.payload,
      };
    case HOME_SHOW_CART:
      return {
        ...state,
        showCart: action.payload,
      };
    case CLEAR_ALL_CART:
      return {
        ...state,
        isClearAllCart: action.payload,
      };
    case GET_DETAIL_EMPLOYEE:
      return {
        ...state,
        detailEmployee: action.payload,
      };
    case SET_DETAIL_EMPLOYEE:
      return {
        ...state,
        setDetailEmployee: action.payload,
      };
    case GET_PACKAGE_BY_ENCRYPTED_PARAMETER:
      return {
        ...state,
        getPackageByEncryptedParameter: action.payload,
        isErrorGetPackageByEncripted: action.isErrorGetPackage,
      };
    case GET_PACKAGES_CONTRACTS:
      return {
        ...state,
        productType: action.payload,
      };
    default:
      return state;
  }
};

import axios from 'axios';
import { API_IBUHOO_EMAIL } from '../config/config';
import { defaultHeaders } from '../helpers/generalUtils';
import GET_BLACK_LIST_EMAIL from '../types/email.type';

/**
 * Get a list of emails of black list form Api ibuhoo email
 * @returns a list with emails of black list or error
 */
export const getBlackListEmailsAction = () => async (dispatch) => {
  try {
    const config = defaultHeaders();
    const response = await axios.get(
      `${API_IBUHOO_EMAIL}/mail/api/Mail/emailsInBlackList`,
      config,
    );
    if (response.status === 200) {
      dispatch({ type: GET_BLACK_LIST_EMAIL, payload: response.data });
    }
  } catch (error) {
    console.error(`error getting black list email ${error}`);
  }
};

export default getBlackListEmailsAction;

/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CustomAutocompleteGoogleMaps from './CustomAutocompleteGoogleMaps';
import { Rules } from '../../../helpers/RHFRules';
import CustomAutocompleteSelect from '../Autocomplete/CustomAutocompleteSelect';
import { getPurchaseOrderParametersAction } from '../../../actions/general.action';
import CustomInput from '../CustomInput';

const AutoCompleteAddressDepartmentCity = (props) => {
  const {
    errors,
    control,
    styles,
    statesAndCities,
    setValue,
    citiesBillGoogleAssistant,
    addressMapsSon,
    setAddressMapsSon,
    inputAddressSonValue,
    setInputAddressSonValue,
    tenant,
    stylesForInput,
    optionsAddressService,
    setOptionsAddressService,
    departaments,
    setDepartaments,
    setGeoCodeService,
    valueDepartamentService,
    setValueDepartamentService,
    valueCityService,
    setValueCityService,
    citiesService,
    setCitiesService,
  } = props;

  const handleChangeStateCustomerServiceId = (idDepartament) => {
    setValueCityService(null);
    const newCities = statesAndCities.statesList.find(
      (d) => d.id === idDepartament,
    );
    if (newCities) {
      setCitiesService(
        newCities.city.sort((a, b) => {
          const x = a.cityName < b.cityName ? -1 : 1;
          return x;
        }),
      );
    }
    return idDepartament;
  };

  useEffect(() => {
    getPurchaseOrderParametersAction();
  }, []);

  useEffect(() => {
    if (statesAndCities) {
      setDepartaments(
        statesAndCities.itemsStatesList.sort((a, b) => {
          const x = a.text < b.text ? -1 : 1;
          return x;
        }),
      );
    }
  }, [statesAndCities]);

  const setCityCustomerServiceId = (
    dptoId,
    dptoName,
    cityId,
    cityName,
    checkServ,
  ) => {
    if (checkServ) {
      let dptoServ = {};
      if (dptoId > 0) {
        dptoServ = statesAndCities.itemsStatesList.find(
          (d) => d.value === dptoId,
        );
      } else {
        dptoServ = statesAndCities.itemsStatesList.find(
          (d) => d.text === dptoName,
        );
      }

      if (dptoServ) {
        setValueDepartamentService(dptoServ);
        setValue('CustomDepartmentIdService', dptoServ);
      }

      let newCities = {};
      if (dptoId > 0) {
        newCities = statesAndCities.statesList.find((d) => d.id === dptoId);
      } else {
        newCities = statesAndCities.statesList.find(
          (d) => d.stateName === dptoName,
        );
      }
      if (newCities) {
        setCitiesService(newCities.city);

        let cityBilling = {};
        if (cityId > 0) {
          cityBilling = newCities.city.find((d) => d.id === cityId);
        } else {
          cityBilling = newCities.city.find((d) => d.cityName === cityName);
        }

        if (cityBilling) {
          setValueCityService(cityBilling);
          setValue('CustomDepartmentIdService', dptoServ.value);
          setValue('CustomCityIdService', cityBilling.id);
        }
      }
    }
  };

  useEffect(() => {
    if (
      citiesBillGoogleAssistant && citiesBillGoogleAssistant.deptoName !== null
    ) {
      setCityCustomerServiceId(
        0,
        citiesBillGoogleAssistant.deptoName,
        0,
        citiesBillGoogleAssistant.cityName,
        true,
      );
    } else if (
      addressMapsSon && optionsAddressService.length > 3
    ) {
      if (!valueDepartamentService) {
        setValueDepartamentService(null);
        setValue('CustomDepartmentIdService', '');
      }
      if (!valueCityService) {
        setValueCityService(null);
        setValue('CustomCityIdService', '');
      }
    }
  }, [citiesBillGoogleAssistant]);

  useEffect(() => {
    if (!valueDepartamentService) {
      setValue('CustomDepartmentIdService', '');
    }
  }, [valueDepartamentService]);

  useEffect(() => {
    if (!valueCityService) {
      setValue('CustomCityIdService', '');
    }
  }, [valueCityService]);

  const handleOnchangeDepartament = (data) => {
    setValueDepartamentService(data);
    handleChangeStateCustomerServiceId(data.value);
  };

  const handleOnchangeCity = (data) => {
    setValueCityService(data);
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        {tenant ? (
          <CustomInput
            label="Dirección"
            name="CustomFulladdressService"
            control={control}
            error={errors}
            required
            rule={Rules.max200Characters}
            styleFromHelperText={{ style: { marginRight: 10 } }}
            classes={stylesForInput}
          />
        ) : (
          <CustomAutocompleteGoogleMaps
            name="CustomFulladdressService"
            options={optionsAddressService}
            label="Dirección"
            placeholder="Escriba la dirección"
            value={addressMapsSon}
            setValueMaps={setAddressMapsSon}
            inputValue={inputAddressSonValue}
            setInputValue={setInputAddressSonValue}
            setOptions={setOptionsAddressService}
            setGeoCoordinate={setGeoCodeService}
            type="Billing"
            showIconLocation
            control={control}
            rules={Rules.required}
            error={errors}
            className={styles}
          />
        ) }
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <CustomAutocompleteSelect
          name="CustomDepartmentIdService"
          options={departaments}
          label="Departamento"
          disableClearable
          value={valueDepartamentService}
          getOptionLabel={(option) => option.text}
          required
          FormHelperTextProps={{ style: { marginRight: 10 } }}
          handleOnchange={handleOnchangeDepartament}
          control={control}
          rule={Rules.required}
          error={errors}
          className={styles}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <CustomAutocompleteSelect
          name="CustomCityIdService"
          options={citiesService}
          label="Ciudad"
          disableClearable
          value={valueCityService}
          getOptionLabel={(option) => option.cityName}
          required
          FormHelperTextProps={{ style: { marginRight: 10 } }}
          handleOnchange={handleOnchangeCity}
          control={control}
          rule={Rules.required}
          error={errors}
          className={styles}
        />
      </Grid>
    </>
  );
};

const mapStateToProps = (props) => ({
  statesAndCities: props.general.statesAndCities,
  citiesBillGoogleAssistant: props.google.citiesBillGoogleAssistant,
});

const mapDispatchToProps = {
  getPurchaseOrderParameters: getPurchaseOrderParametersAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AutoCompleteAddressDepartmentCity);

/* eslint-disable import/prefer-default-export */
import { createTheme } from '@material-ui/core/styles';
import logo from '../assets/img/logo/mySelf.png';
import MuliFont from '../assets/font/muli_font/Muli-Light.ttf';

const muli = {
  fontFamily: 'Muli-Light',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Muli-Light'),
    local('Muli-Light'),
    url(${MuliFont}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export default createTheme({
  app: {
    favicon: 'bemyself.ico',
    title: 'bemyself',
  },
  palette: {
    primary: {
      main: '#EDEDED',
    },
    secondary: {
      main: '#0C0C0C',
    },
    error: {
      main: '#FEA3B1',
    },
  },
  typography: {
    fontFamily: 'Muli, Arial',
    fontSize: 13,
  },
  panelSummary: {
    color: '#335474',
  },
  resendEmailLink: {
    color: '#25DFEB',
    textDecoration: 'underline',
  },
  verifyEmailLink: {
    backgroundColor: '#25DFEB',
    color: '#212330',
  },
  checkOptionFilter: {
    color: '#E1FF2B',
  },
  switchBase: {
    main: '#899393',
    track: '#3F4849',
    checked: '#A6F8FB',
    selectTrack: '#0B9CAF',
  },
  checkOptionHover: {
    color: '#E1FF2B',
  },
  logo,
  styleLogo: {
    zIndex: 1,
  },
  colorHeader: '#141D1E',
  header: {
    usserLogged: {
      color: '#FCFDFD',
    },
  },
  leftMenu: {
    backgroundColor: '',
    color: '',
  },
  home: {
    paquetes: {
      backgroundColor: '#044A95',
    },
    combos: {
      backgroundColor: '#FFEFD8',
      border: '1px solid #ED8D3D',
    },
    agregados: {
      backgroundColor: '#F6F6F6',
      border: '1px solid #B2B2B2',
    },
    backgroundSliderCard: {
      color: 'rgb(255, 255, 255, 0.1)',
    },
    bgColorHead: {
      bases:
        'orange,orange01,orange02,orange,orange01,orange02,orange,orange01,orange02,orange,orange01,orange02',
      combos: 'orange04',
      arrayComplementary:
        '1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4',
      complement: 'red',
      complementaryMyplan: 'orange01',
    },
    sliderComboText: {},
    sliderComplementText: {},
    sliderComplementaryButtonColor: {
      color: '#EDEDED',
    },
    sliderComplementaryButtonTextColor: {
      color: '#F44236',
    },
    sliderBackGroundColor: {},
    sliderArrow: {},
    shoppingCart: {
      color: '#F6F7F9',
    },
    cart: {
      dialogTitle: {
        backgroundColor: '#141D1E',
      },
      dialogContent: {
        backgroundColor: '#141D1E',
      },
      dialogContentCardModal: {
        backgroundColor: '#141D1E',
        marginBottom: 0,
      },
      title: {
        color: '#F6F7F9',
      },
      tableHead: {
        backgroundColor: '#0C0C0C',
        borderBottom: 'hidden',
      },
      cardList: {
        color: '#FCFDFD',
      },
      checkIconBox: {
        width: 15,
        height: 15,
        stroke: '#00FFD1 !important',
      },
      listItem: {
        borderBottom: '1px solid #3F4849',
      },
      discountText: {
        color: '#F6F7F9',
      },
      infoDescountTextResponsive: {
        color: '#FCFDFD',
      },
      totalText: {
        color: '#F6F7F9',
      },
      promoForm: {
        backgroundColor: '#0C0C0C',
        color: '#FFFF',
        border: '1px solid #0C0C0C',
        borderRadius: 8,
      },
      promoFormNoResponsive: {
        backgroundColor: '#0C0C0C',
        color: '#FFFF',
        border: '1px solid #0C0C0C',
        borderRadius: 8,
      },
      slider: {
        backgroundColor: '#0C0C0C',
      },
      quantityPackage: {
        pointerEvents: 'none',
        inputMode: 'none',
        width: '60%',
        margin: 'auto',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#3F4849',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#3F4849',
        },
        '& .MuiOutlinedInput-input': {
          color: '#F6F7F9',
        },
      },
      quantityPackageResponsive: {
        pointerEvents: 'none',
        width: '90%',
        margin: 'auto',
        backgroundColor: '#141D1E !important',
        '& .MuiOutlinedInput-notchedOutline': {
          border: '2px solid #A3ADAE',
        },
        '& .MuiOutlinedInput-input': {
          color: '#F6F7F9',
          backgroundColor: '#141D1E !important',
          width: '100px',
          height: '26px',
          padding: 0,
        },
      },
      dividerMiddle: {
        backgroundColor: '#3F4849',
      },
      listDividerBottom: {
        '& .MuiListItem-divider': {
          borderBottom: '1px solid #3F4849',
        },
      },
      btnPromotional: {
        backgroundColor: '#25DFEB',
        color: '#212330',
        '&:hover': {
          backgroundColor: '#0B9CAF',
        },
        '&:disabled': {
          color: '#3F4849',
          backgroundColor: '#293233',
        },
      },
      btnPromotionalResponsive: {
        backgroundColor: '#25DFEB',
        border: '1px solid',
        color: '#212330',
        '&:disabled': {
          color: '#3F4849',
          backgroundColor: '#293233',
        },
      },
      btnCancelResponsive: {
        backgroundColor: '#141D1E',
        color: '#25DFEB',
        border: '1px solid #25DFEB',
        '&:hover': {
          backgroundColor: '#0B9CAF',
        },
      },
      tableCell: {
        color: '#F6F7F9',
      },
      colorItem: {
        color: '#F6F7F9',
        borderBottomColor: '#3F4849',
      },
      listItemPopover: {
        color: '#FCFDFD !important',
      },
      dividerPopover: {
        borderBottom: 'hidden',
      },
      textPromotional: {
        backgroundColor: '#141D1E',
      },
      colorTextPromotional: {
        color: '#F6F7F9',
        '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
          color: '#F6F7F9',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: '8px',
          borderColor: '#F6F7F9',
        },
        '& .MuiOutlinedInput-input': {
          fontWeight: '500',
          color: '#F6F7F9',
        },
        '& .label.Mui-focused': {
          color: '#F6F7F9',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#F6F7F9',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#F6F7F9',
          },
          '&:hover fieldset': {
            borderColor: '#F6F7F9',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#F6F7F9',
          },
        },
      },
      colorNumberQuantity: {
        color: '#FCFDFD',
      },
      btnContinue: {
        backgroundColor: '#25DFEB',
        color: '#212330',
        fontSize: 14,
        '&:hover': {
          backgroundColor: '#0B9CAF',
        },
      },
      btnContinueResponsive: {
        backgroundColor: '#25DFEB',
        color: '#212330',
      },
      btnAddPackages: {
        backgroundColor: '#141D1E',
        color: '#25DFEB !important',
        border: '2px solid #25DFEB !important',
        float: 'right',
        '&:hover': {
          backgroundColor: '#0C0C0C',
        },
      },
      summaryCart: {
        backgroundColor: '#293233',
      },
      noItems: {
        color: '#F6F7F9',
      },
      tableCellNoItems: {
        color: '#F6F7F9',
      },
      subtotalText: {
        color: '#F6F7F9',
      },
      btnGoToCart: {
        backgroundColor: '#25DFEB',
        color: '#212330',
      },
      btnRemoveFromCart: {
        color: '#F6F7F9',
      },
      detailPackage: {
        backgroundColor: '#293233',
      },
      iconShoppingCart: {
        fill: '#F6F7F9',
        '& .b, .c': {
          stroke: '#F6F7F9',
        },
        iconShoppingCart: {
          fill: '#F6F7F9',
          '& .b, .c': {
            stroke: '#F6F7F9',
          },
        },
      },
      hoverAddProducts: {
        backgroundColor: '#394445',
      },
      titleDetail: {
        color: '#FCFDFD',
      },
      seeMore: {
        color: '#25DFEB',
      },
      seeLess: {
        color: '#25DFEB',
      },
      span: {
        color: '#FCFDFD',
      },
      spanResposive: {
        color: '#FCFDFD',
      },
      borderColor: {
        background: '#293233',
        border: '1px solid #293233',
      },
      customBadge: {
        backgroundColor: '#25DFEB',
      },
      customBadgeItems: '#212330',
      helpIconColor: {
        color: '#FCFDFD',
      },
      helpIconColorLicense: {
        color: '#FCFDFD',
      },
      backgroundDialogResponsive: {
        backgroundColor: '#F5F8FA',
      },
      titleHeader: {
        color: '#FCFDFD',
      },
      deleteIconFromCart: {
        color: '#FCFDFD',
      },
      quantityActionCart: {
        color: '#FCFDFD',
        backgroundColor: '#141D1E !important',
      },
      typographyCard: {
        color: '#FCFDFD',
      },
      moreLessButton: {
        color: '#25DFEB !important',
      },
      spacingContent: {
        color: '#FCFDFD',
      },
      itemListStyles: {
        color: '#F6F7F9',
      },
      itemListTextStyle: {
        color: '#FCFDFD',
      },
    },
  },
  miplan: {
    bgColorCards: {},
    appBgColorCards: {
      boxShadow: 'none',
    },
    rootCard: {},
    title: {
      color: '#E55200',
    },
    subtitle: {
      color: '#6D6E72',
    },
    cardHeader: {
      color: '#E55200',
    },
    featureName: {},
    featureValue: {
      color: '#E55200',
    },
    buttonCard: {
      color: '#fff',
      backgroundColor: '#E45400',
    },
    hoverButtonCard: {
      color: '#fff',
      backgroundColor: '#F5AE19',
    },
    iconCard: {
      color: '#E55200',
    },
  },
  registerForm: {
    masterContainer: {
      backgroundColor: '#141D1E',
    },
    containerForm: {
      backgroundColor: '#141D1E',
      expansionPanel: {
        color: '#FCFDFD',
      },
      titleForm: {
        color: '#FFFFFF',
      },
    },
    activatePlan: {
      buttonActivate: {
        backgroundColor: '#E75200',
        color: '#fff',
      },
      policyAndTerms: {
        color: '#25DFEB',
        textDecoration: 'underline',
      },
    },
    btnCancel: {
      width: '170px',
      backgroundColor: '#25DFEB',
      color: '#212330',
      '&:hover': {
        backgroundColor: '#0B9CAF',
      },
    },
    btnResponsive: {
      backgroundColor: '#25DFEB',
      color: '#212330',
      '&:hover': {
        backgroundColor: '#0B9CAF',
      },
    },
    goHomeLink: {
      color: '#25DFEB',
      fontSize: 18,
      fontFamily: 'Muli',
    },
    titleForm: {
      color: '#FCFDFD',
    },
    contentTextSecondary: {
      main: '#6F797A',
    },
    typeInput: {
      position: 'relative',
      borderRadius: '7px',
      color: '#BEC8C9',
      '& .MuiSelect-iconOutlined': {
        color: '#A3ADAE',
      },
      '& .MuiSvgIcon-root': {
        color: '#A3ADAE',
      },
    },
    checkDigit: {
      main: '#6F797A',
    },
    controlLabel: {
      '& .MuiFormControlLabel-label.Mui-disabled': {
        color: '#EDEDED',
      },
      color: '#EDEDED',
    },
    colorLabelFocus: {
      color: '#EDEDED',
    },
    colorLabel: '#A3ADAE',
    colorInputError: '#FEA3B1 !important',
    colorBorderError: {
      border: '1px solid #FEA3B1 !important',
    },
    colorBorderNormal: {
      border: '1px solid #A3ADAE !important',
    },
    dataTreatment: {
      color: '#FCFDFD',
    },
    controlLabelModify: {
      color: '#EDEDED',
    },
    colorCkeckNormal: {
      color: '#FCFFC4',
    },
    btnNoResponsiveCancel: {
      width: '170px',
      backgroundColor: '#141D1E',
      color: '#25DFEB',
      border: '1px solid #25DFEB',
      '&:hover': {
        backgroundColor: '#0B9CAF',
      },
    },
    handleBackMenuButton: {
      color: '#F6F7F9',
    },
    typeInputValidationCode: {
      position: 'relative',
      borderRadius: '7px',
      color: '#BEC8C9',
    },
    colorLabelValidationCode: '#A3ADAE',
    colorBorderNormalValidationCode: {
      border: '1px solid #A3ADAE !important',
    },
  },
  statusLicense: {
    containerMessage: {
      backgroundColor: '#141D1E',
    },
    titleStatusLicense: {
      color: '#F6F7F9',
    },
    summaryOrderPapper: {
      backgroundColor: '#141D1E',
    },
    buttonAction: {
      backgroundColor: '#25DFEB',
      color: '#212330',
      '&:hover': {
        backgroundColor: '#25DFEB',
      },
    },
    appoveLicense: {
      color: '#F6F7F9',
    },
    detailStatus: {
      color: '#FCFDFD',
    },
  },
  alertPopup: {
    iconClose: {
      color: '#FCFDFD',
    },
    titleAlert: {
      color: '#F6F7F9',
    },
    buttonCancel: {
      backgroundColor: '#2499EF',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#011E3D',
      },
    },
    buttonAccept: {
      backgroundColor: '#25DFEB',
      color: '#212330',
      '&:hover': {
        backgroundColor: '#25DFEB',
      },
    },
    secondaryMessageText: {
      color: '#c3c3c3',
    },
    mensajeModalMain: {
      color: '#F6F7F9',
    },
  },
  summaryOrder: {
    loadFiles: {
      backgroundColor: '#F6F7F924',
      color: '#FCFDFD',
    },
    totalPurchase: {
      color: '#F6F7F9',
    },
    tableCellSummaryOrder: {
      color: '#F6F7F9',
      borderBlockColor: '#3F4849',
      fontFamily: 'Mulish',
    },
    purchaseSummary: {
      color: '#F6F7F9',
      fontFamily: 'Mulish',
    },
    attachReceiptButtons: {
      color: '#25DFEB',
      borderColor: '#25DFEB',
    },
    textSummaryOrder: {
      '&.MuiTypography-h4': {
        color: '#F6F7F9',
        marginLeft: 20,
        fontWeight: 'bold',
      },
    },
    purchaseEmpty: {
      '&.MuiTypography-body1': {
        marginBottom: 10,
        fontWeight: 500,
        color: '#F6F7F9',
      },
    },
    formLabelColor: {
      color: '#FCFDFD',
    },
    linearProgress: {
      backgroundColor: '#566061',
    },
    progressBarBackgroundColor: '#25DFEB',
    dividerStyles: {
      background: '#3F4849',
    },
  },
  backOffice: {
    configurations: {
      title: {
        color: '#003582',
      },
      editIcon: {
        color: '#009BDD',
      },
    },
  },
  helpToolTip: {
    root: {
      backgroundColor: '#293233',
      color: '#FCFDFD',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        fontWeight: 'bold',
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#0C0C0C',
          WebkitBackgroundSize: 'cover',
          MozBackgroundSize: 'cover',
          OBackgroundSize: 'cover',
          backgroundSize: 'cover',
        },
        '@font-face': [muli],
      },
    },
  },
});

/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  Stepper, Step, StepLabel, Box, Button, IconButton,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/es';
import moment from 'moment';
import CustomInput from '../../../components/form/CustomInput';
import CustomSelect from '../../../components/form/CustomSelect';
import AutocompleteGoogleMaps from '../../../components/form/AutocompeteGoogleMaps/CustomAutocompleteGoogleMaps';
import { Rules } from '../../../helpers/RHFRules';
import {
  getPurchaseOrderParametersAction,
} from '../../../actions/general.action';
import {
  saveContractAction, getManagersAction, getAppParametersContractsAction, getContractDataAction,
} from '../../../actions/license.action';
import { getPackagesContractsAction } from '../../../actions/home.action';
import { getDocumentTypesContracts } from '../../../helpers/documentsTypes';
import { getTenantByRole } from '../../../helpers/generalUtils';
import AutoCompleteAddressDepartmentCity from '../../../components/form/AutocompeteGoogleMaps/AutoCompleteAddressDepartmentCity';
import AlertConfirm from '../../../components/crosscutting/AlertConfirm';
import Loading from '../../../components/crosscutting/Loading';
import { getBlackListEmailsAction } from '../../../actions/email.action';

const useStyles = makeStyles((theme) => ({
  paper: {
    background: 'linear-gradient(#FFFFFFCC, #ffffff)',
    borderRadius: '30px',
    opacity: 1,
    padding: '5px',
    marginTop: '20px',
  },
  title: {
    fontWeight: 600,
    fontSize: '26px',
    paddingTop: '15px',
    paddingLeft: '20px',
    color: theme.backOffice.contracts.title.color,
  },
  titleForm: {
    color: theme.backOffice.contracts.titleForm.color,
    textAlign: 'left',
    letterSpacing: '0px',
    opacity: '1',
    fontWeight: 'bold',
  },
  typeInput: {
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      borderRadius: '10px',
      boxShadow: '1px 5px 5px #d7d7d7',
    },
  },
  paperGroup: {
    backgroundColor: '#fff',
    margin: 20,
    borderRadius: 10,
    border: '1px solid #32ACDE',
    padding: 20,
  },
  dateCustom: {
    '& .MuiIconButton-root': {
      color: theme.palette.primary.main,
    },
  },
  btnSubmit: {
    marginLeft: 10,
    fontSize: 16,
    width: 200,
    borderRadius: 10,
    fontWeight: 100,
    '&:disabled': {
      opacity: 0.4,
      fontWeight: 100,
      backgroundColor: theme.backOffice.contracts.btnSteps.backgroundColor,
      color: 'white',
    },
  },
  scrollBar: {
    '&.MuiTableContainer-root': {
      scrollbarColor: `${theme.backOffice.contracts.tableProducts.backgroundColor} #fff`,
    },
  },
  tableHead: {
    width: '828px',
    height: '40px',
    background: 'var(--unnamed-color-009bdd) 0% 0% no-repeat padding-box',
    borderRadius: '10px',
    opacity: 1,
    color: '#FFF',
    backgroundColor: theme.backOffice.contracts.tableProducts.backgroundColor,
  },
  tableCell: {
    fontWeight: 'bold',
    fontSize: '16px',
    ...theme.backOffice.contracts.tableProducts,
  },
}));

const personType = [
  { value: 'J', label: 'Persona Juridica' },
  { value: 'N', label: 'Persona Natural' },
];

const steps = ['Información Contrato', 'Información Cliente', 'Información Supervisor', 'Servicios Contratados'];

const Agreements = (props) => {
  const {
    statesAndCities, citiesServGoogleAssistant, getPurchaseOrderParameters,
    documentTypes, tenant,
    saveContract, getManagers, gestorType,
    getPackagesContracts, productType,
    loading, getBlackListEmails, emailsInBalckList, getAppParametersContracts, listPaymentTypeContracts, listDocumentTypeContracts,
    listValidityTypeContracts, listDeviceTypeContracts, listCredentialDeliveryContracts,
    parameterMaxReassignmentQuota, transformedListMaxValidityAmountContracts, getContractData, contractData, decodedToken,
  } = props;
  const {
    handleSubmit, errors, control, getValues, setValue, clearErrors, watch, setError,
  } = useForm({
    mode: 'onChange',
  });

  const {
    handleSubmit: handleSubmitProducts,
    errors: errorsProducts,
    control: controlProducts,
    setValue: setValueProducts,
    reset: resetProduct,
    clearErrors: clearErrorsProducts,
    getValues: getValuesProducts,
  } = useForm({
    mode: 'onChange',
  });

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const watchDocTypeSer = watch('documentTypeIdService');
  const watchDocTypeDocSupervisor = watch('documentTypeIdSupervisor');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [personTypeService, setPersonTypeService] = React.useState('N');
  const [optionsAddressService, setOptionsAddressService] = React.useState([]);
  const [addressMapsService, setAddressMapsService] = React.useState(null);
  const [inputAddressServiceValue, setInputAddressServiceValue] = React.useState('');
  const [, setGeoCodeService] = React.useState({ lat: null, lng: null });
  const [departaments, setDepartaments] = useState([]);
  const [citiesService, setCitiesService] = useState([]);
  const [valueDepartamentService, setValueDepartamentService] = React.useState(null);
  const [valueCityService, setValueCityService] = React.useState(null);
  const [servicesToAdd, setServicesToAdd] = React.useState([]);
  const classes = useStyles();
  const [addressMapsSon, setAddressMapsSon] = useState();
  const [inputAddressSonValue, setInputAddressSonValue] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [credentialsOptions, setCredentialsOptions] = useState(listCredentialDeliveryContracts);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [dataComplete, setDataComplete] = useState(null);
  const [lastValidValueQuantityProducts, setLastValidValueQuantityProducts] = useState('');
  const [invalidDomains, setInvalidDomains] = useState([]);
  const [invalidContract, setInvalidContract] = useState(false);

  const [optionsAddressSupervisor, setOptionsAddressSupervisor] = useState([]);
  const [departamentsSupervisor, setDepartamentsSupervisor] = useState([]);
  const [, setGeoCodeSupervisor] = useState({
    lat: null,
    lng: null,
  });
  const [valueDepartamentSupervisor, setValueDepartamentSupervisor] = useState(null);
  const [valueCitySupervisor, setValueCitySupervisor] = useState(null);
  const [citiesSupervisor, setCitiesSupervisor] = useState([]);

  const setCityCustomerServiceId = (dptoId, dptoName, cityId, cityName, checkServ) => {
    if (checkServ) {
      let dptoServ = {};
      if (dptoId > 0) {
        dptoServ = statesAndCities.itemsStatesList.find((d) => d.value === dptoId);
      } else {
        dptoServ = statesAndCities.itemsStatesList.find((d) => d.text === dptoName);
        // dptoBilling = statesAndCities.itemsStatesList.find((d) => dptoName.include(d.text));
      }

      if (dptoServ) {
        setValueDepartamentService(dptoServ);
        setValue('departmentIdService', dptoServ);
        clearErrors('departmentIdService');
      }

      let newCities = {};
      if (dptoId > 0) {
        newCities = statesAndCities.statesList.find((d) => d.id === dptoId);
      } else {
        newCities = statesAndCities.statesList.find((d) => d.stateName === dptoName);
      }
      if (newCities) {
        setCitiesService(newCities.city);

        let cityBilling = {};
        if (cityId > 0) {
          cityBilling = newCities.city.find((d) => d.id === cityId);
        } else {
          cityBilling = newCities.city.find((d) => d.cityName === cityName);
        }

        if (cityBilling) {
          setValueCityService(cityBilling);
          setValue('departmentIdService', dptoServ.value);
          clearErrors('departmentIdService');
          setValue('cityIdService', cityBilling.id);
          clearErrors('cityIdService');
        }
      }
    }
  };

  useEffect(() => {
    getPurchaseOrderParameters();
    getManagers(`LICENSE_${getTenantByRole(decodedToken)}_COMERCIALMANAGER`);
    getPackagesContracts(getTenantByRole(decodedToken));
    getAppParametersContracts(getTenantByRole(decodedToken), 'ParametersDocumentType', 'ParameterMaxReassignmentQuota', 'ParameterMaxValidityAmount');
    getBlackListEmails();
  }, []);

  useEffect(() => {
    if (statesAndCities) {
      setDepartaments(statesAndCities.itemsStatesList.sort((a, b) => {
        const x = a.text < b.text ? -1 : 1;
        return x;
      }));
    }
  }, [statesAndCities]);

  /**
   * emails in black list retrived of redux
   * then parse into a unique list
   */
  useEffect(() => {
    if (emailsInBalckList !== null && emailsInBalckList !== undefined) {
      const invalidsDomains = [];
      emailsInBalckList.forEach((email) => {
        invalidsDomains.push(email.regla);
      });
      setInvalidDomains(invalidsDomains);
    }
  }, [emailsInBalckList]);

  /**
   * Validate if domain is not in black list
   */
  const validateEmail = (value) => {
    if (!value) {
      return true;
    }
    // Verify if email have a domain in black list
    const domain = value.split('@')[1];
    if (domain && invalidDomains.includes(`@${domain}`)) {
      return 'El dominio de correo ingresado no es permitido.';
    }
    return true;
  };

  useEffect(() => {
    if (citiesServGoogleAssistant && citiesServGoogleAssistant.deptoName !== null) {
      setCityCustomerServiceId(
        0, citiesServGoogleAssistant.deptoName, 0, citiesServGoogleAssistant.cityName, true,
      );
    } else if (addressMapsService && optionsAddressService.length > 3) {
      if (!valueDepartamentService) {
        setValueDepartamentService(null);
        setValue('departmentIdService', '');
      }
      if (!valueCityService) {
        setValueCityService(null);
        setValue('cityIdService', '');
      }
    }
  }, [citiesServGoogleAssistant]);

  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    }
  }, [formData, setValue, activeStep]);

  useEffect(() => {
    if (!valueDepartamentService) {
      setValue('departmentIdService', '');
    }
  }, [valueDepartamentService]);

  useEffect(() => {
    if (!valueCityService) {
      setValue('cityIdService', '');
    }
  }, [valueCityService]);

  const handleNext = (data) => {
    const currentData = getValues();

    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));

    if (activeStep === steps.length - 1) {
      const transformedServices = servicesToAdd.map((item) => ({
        packageCode: item.productService,
        quantityToAdd: parseInt(item.quantityToAddService, 10),
        identifierCode: `${item.productService}_${listDeviceTypeContracts.find((validity) => validity.value === item.deviceType)?.codeDevice}_${item.validityAmount}${item.validityService}`,
        typeValidity: item.validityService,
        validityAmount: parseInt(item.validityAmount, 10),
        reassignmentQuota: parseInt(item.reassignmentQuota, 10),
        deviceType: item.deviceType,
        credentialsDeliveryType: item.credentialsDeliveryType,
      }));
      const finalData = { ...formData, ...currentData };
      finalData.servicesToAdd = transformedServices;
      setDataComplete(finalData);
      setOpenAlertConfirm(true);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    const currentData = getValues();
    setFormData((prevData) => ({
      ...prevData,
      ...currentData,
    }));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setPersonType = (event) => {
    setValue('documentTypeIdService', '');
    setValue('legalNameService', '');
    setValue('firstNameService', '');
    setValue('lastNameService', '');
    setValue('documentNumberService', '');
    const { value } = event.target;

    if (event.target.name === 'personTypeService') {
      setPersonTypeService(value);
    }
    return value;
  };

  const getDocumentsTypesSupervisor = () => {
    const documents = [];
    getDocumentTypesContracts(documentTypes, 'N', getTenantByRole(decodedToken), listDocumentTypeContracts, true).map(
      (td) => (
        documents.push({
          value: td.value,
          label: td.text,
        })
      ),
    );
    return documents;
  };

  const setGestorType = (event) => {
    const { value } = event.target;
    if (event.target.name === 'gestorService') {
      setValue('gestorService', value);
    }
    return value;
  };

  const setPaymentType = (event) => {
    const { value } = event.target;
    if (event.target.name === 'paymentService') {
      setValue('paymentService', value);
    }
    return value;
  };

  const setValidityType = (event) => {
    const { value } = event.target;
    if (event.target.name === 'validityService') {
      setValueProducts('validityService', value);
      setValueProducts('validityAmount', '');
    }
    return value;
  };

  const validateDocument = (persontype, watchDocType) => {
    if (watchDocType === 6 || watchDocType === 10 || watchDocType === 13) {
      return Rules.pasaporte;
    } if (watchDocType === 4 && persontype === 'J') {
      return Rules.tipoDocumentoNIT;
    }
    return Rules.otrosDocumentos;
  };

  const handleChangeStateCustomerServiceId = (idDepartament) => {
    setValueCityService(null);
    const newCities = statesAndCities.statesList.find((d) => d.id === idDepartament);
    if (newCities) {
      setCitiesService(newCities.city.sort((a, b) => {
        const x = a.cityName < b.cityName ? -1 : 1;
        return x;
      }));
    }
    return idDepartament;
  };

  const handleAgregarProducto = (data) => {
    if (isEditing) {
      const codeDevice = listDeviceTypeContracts.find((validity) => validity.value === data.deviceType)?.codeDevice;
      const identifierCode = `${data.productService}_${codeDevice}_${data.validityAmount}${data.validityService}`;

      const exists = servicesToAdd.some((serv, index) => {
        const codeDeviceService = listDeviceTypeContracts.find((validity) => validity.value === serv.deviceType)?.codeDevice;
        return index !== selectedIndex && identifierCode === `${serv.productService}_${codeDeviceService}_${serv.validityAmount}${serv.validityService}`;
      });

      if (exists) {
        enqueueSnackbar('Ya existe el producto en la lista.', {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        return;
      }
      setServicesToAdd((prevServices) => prevServices.map((service, index) => (index === selectedIndex ? { ...service, ...data } : service)));
    } else {
      const codeDevice = listDeviceTypeContracts.find((validity) => validity.value === data.deviceType)?.codeDevice;
      const identifierCode = `${data.productService}_${codeDevice}_${data.validityAmount}${data.validityService}`;

      const exists = servicesToAdd.some((serv) => {
        const codeDeviceService = listDeviceTypeContracts.find((validity) => validity.value === serv.deviceType)?.codeDevice;
        return identifierCode === `${serv.productService}_${codeDeviceService}_${serv.validityAmount}${serv.validityService}`;
      });

      if (exists) {
        enqueueSnackbar('Ya existe el producto en la lista.', {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        return;
      }

      setServicesToAdd((prevServices) => [...prevServices, data]);
    }
    setValueProducts('productService', '');
    setValueProducts('quantityToAddService', '');
    setValueProducts('validityService', '');
    setValueProducts('validityAmount', '');
    setValueProducts('reassignmentQuota', '');
    setValueProducts('deviceType', '');
    setValueProducts('credentialsDeliveryType', '');
    setIsEditing(false);
    setSelectedIndex(null);
    resetProduct();
    setDeviceTypeOptions([]);
    setCredentialsOptions([]);
  };

  const handleCancelEdit = () => {
    setValueProducts('productService', '');
    setValueProducts('quantityToAddService', '');
    setValueProducts('validityService', '');
    setValueProducts('validityAmount', '');
    setValueProducts('reassignmentQuota', '');
    setValueProducts('deviceType', '');
    setValueProducts('credentialsDeliveryType', '');
    setIsEditing(false);
    setSelectedIndex(null);
    resetProduct();
    setDeviceTypeOptions([]);
    setCredentialsOptions([]);
  };

  const setProductType = (event) => {
    setValueProducts('deviceType', '');
    setValueProducts('credentialsDeliveryType', '');
    const { value } = event.target;
    if (event.target.name === 'productService') {
      setValueProducts('productService', value);
    }
    if (productType.length > 0) {
      const deviceTypeId = productType.find((pack) => pack.value === getValuesProducts('productService'))?.deviceTypeId;
      const deviceTypeIdsArray = deviceTypeId ? deviceTypeId.split(',').map(Number) : [];
      if (deviceTypeIdsArray.length > 0) {
        setDeviceTypeOptions(listDeviceTypeContracts.filter((option) => deviceTypeIdsArray.includes(option.value)));
      }
    }
    return value;
  };

  const setDeviceType = (event) => {
    setValueProducts('credentialsDeliveryType', '');
    const { value } = event.target;
    if (event.target.name === 'deviceType') {
      setValueProducts('deviceType', value);
    }
    if (value === 1) {
      setCredentialsOptions(listCredentialDeliveryContracts.filter((option) => option.value === 1));
    } else if (value === 2) {
      setCredentialsOptions(listCredentialDeliveryContracts);
    }
    return value;
  };

  const setCredentialsType = (event) => {
    const { value } = event.target;
    if (event.target.name === 'credentialsDeliveryType') {
      setValueProducts('credentialsDeliveryType', value);
    }
    return value;
  };

  const handleDeleteProduct = (index) => {
    setValueProducts('productService', '');
    setValueProducts('quantityToAddService', '');
    setValueProducts('validityService', '');
    setValueProducts('validityAmount', '');
    setValueProducts('reassignmentQuota', '');
    setValueProducts('deviceType', '');
    setValueProducts('credentialsDeliveryType', '');
    setIsEditing(false);
    setSelectedIndex(null);
    resetProduct();
    setServicesToAdd((prevServices) => prevServices.filter((_, i) => i !== index));
    setDeviceTypeOptions([]);
    setCredentialsOptions([]);
  };

  const handleEditProduct = (index) => {
    const product = servicesToAdd[index];

    setValueProducts('productService', product.productService);
    setValueProducts('quantityToAddService', product.quantityToAddService);
    setValueProducts('validityService', product.validityService);
    setValueProducts('validityAmount', product.validityAmount);
    setValueProducts('reassignmentQuota', product.reassignmentQuota);
    if (productType.length > 0) {
      const deviceTypeId = productType.find((pack) => pack.value === product.productService)?.deviceTypeId;
      const deviceTypeIdsArray = deviceTypeId ? deviceTypeId.split(',').map(Number) : [];
      if (deviceTypeIdsArray.length > 0) {
        setDeviceTypeOptions(listDeviceTypeContracts.filter((option) => deviceTypeIdsArray.includes(option.value)));
      }
    }
    setValueProducts('deviceType', product.deviceType);
    if (product.deviceType === 1) {
      setCredentialsOptions(listCredentialDeliveryContracts.filter((option) => option.value === 1));
    } else {
      setCredentialsOptions(listCredentialDeliveryContracts);
    }
    setValueProducts('credentialsDeliveryType', product.credentialsDeliveryType);
    setIsEditing(true);
    setSelectedIndex(index);
    clearErrorsProducts();
  };

  const confirmSaveContract = () => {
    if (dataComplete) {
      saveContract(dataComplete, getTenantByRole(decodedToken), enqueueSnackbar);
    }
    setOpenAlertConfirm(false);
  };

  const handleSearchContracNumber = (contractNumber) => {
    getContractData(contractNumber, getTenantByRole(decodedToken));
  };

  useEffect(() => {
    if (contractData && contractData.existContract) {
      setError('contractService', {
        type: 'required',
        message: 'Número de contrato ya se encuentra registrado.',
      });
      setInvalidContract(true);
    } else {
      setInvalidContract(false);
    }
  }, [contractData]);

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Grid item xs={12} md={6}>
              <CustomInput
                label="Número de contrato"
                name="contractService"
                control={control}
                error={errors}
                required
                styleFromHelperText={{ style: { marginRight: 10 } }}
                // rule={(e) => validateContract(e.target.value)}
                rule={{
                  ...Rules.required,
                }}
                inputProps={{
                  maxLength: 100,
                  onBlur: (e) => handleSearchContracNumber(e.target.value),
                  onChange: () => setInvalidContract(true),
                }}
                classes={classes.typeInput}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl style={{ width: '100%' }}>
                <Controller
                  name="startDate"
                  control={control}
                  rules={{
                    required: 'Campo obligatorio',
                    validate: {
                      validDate: (value) => {
                        if (!moment(value, 'DD/MM/YYYY', true).isValid()) {
                          return 'Fecha inválida, el formato debe ser dd/MM/yyyy';
                        }
                        const minDate = moment('01/01/2000', 'DD/MM/YYYY');
                        const maxDate = moment();
                        const selectedDate = moment(value, 'DD/MM/YYYY');
                        if (selectedDate.isBefore(minDate)) {
                          return 'Fecha mínima permitida 01/01/2000';
                        }
                        if (selectedDate.isAfter(maxDate)) {
                          return `Fecha máxima permitida ${maxDate.format('DD/MM/YYYY')}`;
                        }
                        return true;
                      },
                    },
                  }}
                  defaultValue={startDate}
                  render={() => (
                    <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={classes.dateCustom}
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        label="Fecha inicio"
                        size="small"
                        color="primary"
                        value={startDate}
                        maxDate={moment()}
                        onChange={(date) => {
                          setValue('endDate', '');
                          setEndDate(null);
                          setValue('startDate', moment(date));
                          setStartDate(moment(date));
                          if (moment(date, 'DD/MM/YYYY', true).isValid()
                            && !moment(date).isBefore(moment('01/01/2000', 'DD/MM/YYYY'))
                            && !moment(date).isAfter(moment())) {
                            clearErrors('startDate');
                          }
                        }}
                        autoOk
                        error={!!errors.startDate}
                        helperText={errors.startDate ? errors.startDate.message : ''}
                        InputProps={{
                          style: {
                            borderRadius: '10px',
                            boxShadow: '1px 5px 5px #d7d7d7',
                          },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl style={{ width: '100%' }}>
                <Controller
                  name="endDate"
                  control={control}
                  rules={{
                    required: 'Campo obligatorio',
                    validate: {
                      validDate: (value) => {
                        const minDate = startDate ? moment(startDate) : moment();
                        const maxDate = startDate ? moment(startDate).add(2, 'years') : moment();
                        if (!moment(value, 'DD/MM/YYYY', true).isValid()) {
                          return 'Fecha inválida, el formato debe ser dd/MM/yyyy';
                        }
                        if (moment(value).isBefore(minDate)) {
                          return `La fecha debe ser posterior a ${minDate.format('DD/MM/YYYY')}`;
                        }
                        if (moment(value).isAfter(maxDate)) {
                          return `La fecha no puede ser mayor a ${maxDate.format('DD/MM/YYYY')}`;
                        }
                        return true;
                      },
                    },
                  }}
                  defaultValue={endDate}
                  render={() => (
                    <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={classes.dateCustom}
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        label="Fecha Fin"
                        size="small"
                        color="primary"
                        value={endDate}
                        minDate={startDate ? moment(startDate).add(1, 'days') : moment()}
                        maxDate={startDate ? moment(startDate).add(2, 'years') : moment()}
                        onChange={(date) => {
                          setValue('endDate', moment(date));
                          setEndDate(moment(date));
                          clearErrors('endDate');
                        }}
                        autoOk
                        error={!!errors.endDate}
                        helperText={errors.endDate ? errors.endDate.message : ''}
                        InputProps={{
                          style: {
                            borderRadius: '10px',
                            boxShadow: '1px 5px 5px #d7d7d7',
                          },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid item xs={12} style={{ paddingTop: 5, paddingBottom: 0 }}>
                <h3>Gestor Comercial</h3>
              </Grid>
              <Grid item xs={12}>
                <InputLabel />
                <CustomSelect
                  label="Seleccione"
                  name="gestorService"
                  options={gestorType}
                  control={control}
                  required
                  rule={Rules.required}
                  error={errors}
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  inputProps={{ onChange: (e) => setGestorType(e) }}
                  classes={classes.typeInput}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid item xs={12} style={{ paddingTop: 5, paddingBottom: 0 }}>
                <h3>Condición de Pago</h3>
              </Grid>
              <Grid item xs={12}>
                <InputLabel />
                <CustomSelect
                  label="Seleccione"
                  name="paymentService"
                  options={listPaymentTypeContracts}
                  control={control}
                  required
                  rule={Rules.required}
                  error={errors}
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  inputProps={{ onChange: (e) => setPaymentType(e) }}
                  classes={classes.typeInput}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} />
          </>
        );
      case 1:
        return (
          <>
            <Grid item xs={12}>
              <h3>Información Cliente</h3>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel />
              <CustomSelect
                label="Tipo de persona"
                name="personTypeService"
                options={personType}
                control={control}
                required
                rule={Rules.required}
                error={errors}
                styleFromHelperText={{ style: { marginRight: 10 } }}
                inputProps={{ onChange: (e) => setPersonType(e) }}
                classes={classes.typeInput}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Controller
                    as={(
                      <TextField
                        select
                        label="Tipo Identificación"
                        size="small"
                        variant="outlined"
                        required
                        fullWidth
                        style={{ marginRight: 10, color: 'red' }}
                        FormHelperTextProps={{ style: { marginRight: 10 } }}
                        helperText={errors.documentTypeIdBilling ? errors.documentTypeIdBilling.message : ''}
                        className={classes.typeInput}
                      >
                        <MenuItem value="">Seleccione</MenuItem>
                        {
                          getDocumentTypesContracts(documentTypes, personTypeService, getTenantByRole(decodedToken), listDocumentTypeContracts).map(
                            (td) => (
                              <MenuItem
                                key={td.value}
                                value={td.value}
                              >
                                {td.text}
                              </MenuItem>
                            ),
                          )
                        }
                      </TextField>
                    )}
                    name="documentTypeIdService"
                    control={control}
                    rules={Rules.required}
                    error={Boolean(errors.documentTypeIdService)}
                    defaultValue=""
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <CustomInput
                    label="Identificación"
                    name="documentNumberService"
                    control={control}
                    rule={validateDocument(personTypeService, watchDocTypeSer)}
                    error={errors}
                    required
                    styleFromHelperText={{ style: { marginRight: 10 } }}
                    classes={classes.typeInput}
                  />
                </Grid>
              </Grid>
            </Grid>
            {
              personTypeService === 'J'
                ? (
                  <Grid item xs={12}>
                    <CustomInput
                      label="Razón Social"
                      name="legalNameService"
                      control={control}
                      rule={Rules.DenySpecialCharactersLegalName}
                      error={errors}
                      required
                      styleFromHelperText={{ style: { marginRight: 10 } }}
                      inputProps={{
                        maxLength: 450,
                      }}
                      classes={classes.typeInput}
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        label="Nombres"
                        name="firstNameService"
                        control={control}
                        rule={Rules.letrasTildesMin3}
                        error={errors}
                        required
                        styleFromHelperText={{ style: { marginRight: 10 } }}
                        inputProps={{
                          maxLength: 100,
                        }}
                        classes={classes.typeInput}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <CustomInput
                        label="Apellidos"
                        name="lastNameService"
                        control={control}
                        rule={Rules.letrasTildesMin3}
                        error={errors}
                        required
                        styleFromHelperText={{ style: { marginRight: 10 } }}
                        inputProps={{
                          maxLength: 100,
                        }}
                        classes={classes.typeInput}
                      />
                    </Grid>
                  </>
                )
            }
            <Grid item xs={12} md={6}>
              {tenant ? (
              // If tenant show Input Without assistant
                <CustomInput
                  label="Dirección"
                  name="fulladdressService"
                  control={control}
                  error={errors}
                  required
                  rule={Rules.max200Characters}
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  classes={classes.typeInput}
                />
              ) : (
              // If tenant is null, show other component.
                <AutocompleteGoogleMaps
                  name="fulladdressService"
                  options={optionsAddressService}
                  disabled={!(statesAndCities && Object.keys(statesAndCities).length > 0)}
                  label="Dirección"
                  placeholder="Escriba la dirección"
                  value={addressMapsService}
                  setOptions={setOptionsAddressService}
                  setValueMaps={setAddressMapsService}
                  inputValue={inputAddressServiceValue}
                  setInputValue={setInputAddressServiceValue}
                  setGeoCoordinate={setGeoCodeService}
                  type="Service"
                  showIconLocation
                  control={control}
                  rules={Rules.required}
                  error={errors}
                  className={classes.typeInput}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Controller
                render={(props) => (
                  <Autocomplete
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    options={departaments}
                    noOptionsText="No hay registros"
                    size="small"
                    disableClearable
                    required
                    fullWidth
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Departamento"
                        variant="outlined"
                        required
                        error={Boolean(errors.departmentIdService)}
                        style={{ marginRight: 10, color: 'red' }}
                        FormHelperTextProps={{ style: { marginRight: 10 } }}
                        helperText={errors.departmentIdService ? errors.departmentIdService.message : ''}
                        className={classes.typeInput}
                      />
                    )}
                    value={valueDepartamentService}
                    onChange={(_, data) => {
                      // eslint-disable-next-line react/destructuring-assignment
                      props.onChange(data);
                      if (data) {
                        setValueDepartamentService(data);
                        handleChangeStateCustomerServiceId(data.value);
                      }
                    }}
                  />
                )}
                defaultValue=""
                name="departmentIdService"
                control={control}
                rules={Rules.required}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Controller
                render={(props) => (
                  <Autocomplete
                    {...props}
                    options={citiesService}
                    noOptionsText="No hay registros"
                    disableClearable
                    size="small"
                    required
                    fullWidth
                    getOptionLabel={(option) => option.cityName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Municipio"
                        variant="outlined"
                        required
                        error={Boolean(errors.cityIdService)}
                        style={{ marginRight: 10, color: 'red' }}
                        FormHelperTextProps={{ style: { marginRight: 10 } }}
                        helperText={errors.cityIdService ? errors.cityIdService.message : ''}
                        className={classes.typeInput}
                      />
                    )}
                    value={valueCityService}
                    onChange={(_, data) => {
                      props.onChange(data);
                      if (data) {
                        setValueCityService(data);
                      }
                    }}
                  />
                )}
                defaultValue=""
                name="cityIdService"
                control={control}
                rules={Rules.required}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                label="Teléfono / Celular"
                name="cellphoneNumberService"
                control={control}
                rule={Rules.telCel}
                error={errors}
                required
                styleFromHelperText={{ style: { marginRight: 10 } }}
                inputProps={{
                  maxLength: 10,
                }}
                classes={classes.typeInput}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                label="Correo Electrónico"
                name="emailService"
                control={control}
                rule={{
                  ...Rules.email,
                  validate: validateEmail, // personalizated validation
                }}
                error={errors}
                required
                styleFromHelperText={{ style: { marginRight: 10 } }}
                inputProps={{
                  maxLength: 100,
                }}
                classes={classes.typeInput}
              />
            </Grid>
          </>
        );
      case 2:
        return (
          <Grid container spacing={3} alignItems="center" style={{ marginBottom: '10px' }}>
            <Grid item xs={12}>
              <h3>Información Supervisor</h3>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel />
              <CustomSelect
                label="Tipo Identificación"
                name="documentTypeIdSupervisor"
                // eslint-disable-next-line no-nested-ternary
                options={getDocumentsTypesSupervisor()}
                control={control}
                required
                rule={Rules.required}
                error={errors}
                styleFromHelperText={{ style: { marginRight: 10 } }}
                // inputProps={{ onChange: (e) => setPersonType(e) }}
                classes={classes.typeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Identificación:"
                name="documentNumberSupervisor"
                control={control}
                error={errors}
                required
                rule={validateDocument('N', watchDocTypeDocSupervisor)}
                styleFromHelperText={{ style: { marginRight: 10 } }}
                classes={classes.typeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Nombres: "
                name="namesSupervisor"
                control={control}
                error={errors}
                required
                rule={Rules.letrasTildesMin3}
                styleFromHelperText={{ style: { marginRight: 10 } }}
                classes={classes.typeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Apellidos: "
                name="lastNameSupervisor"
                control={control}
                error={errors}
                required
                rule={Rules.letrasTildesMin3}
                styleFromHelperText={{ style: { marginRight: 10 } }}
                classes={classes.typeInput}
              />
            </Grid>
            <AutoCompleteAddressDepartmentCity
              control={control}
              errors={errors}
              styles={classes.typeInput}
              setValue={setValue}
              getValues={getValues}
              addressMapsSon={addressMapsSon}
              setAddressMapsSon={setAddressMapsSon}
              inputAddressSonValue={inputAddressSonValue}
              setInputAddressSonValue={setInputAddressSonValue}
              tenant={tenant}
              stylesForInput={classes.typeInput}
              optionsAddressService={optionsAddressSupervisor}
              setOptionsAddressService={setOptionsAddressSupervisor}
              departaments={departamentsSupervisor}
              setDepartaments={setDepartamentsSupervisor}
              setGeoCodeService={setGeoCodeSupervisor}
              valueDepartamentService={valueDepartamentSupervisor}
              setValueDepartamentService={setValueDepartamentSupervisor}
              valueCityService={valueCitySupervisor}
              setValueCityService={setValueCitySupervisor}
              citiesService={citiesSupervisor}
              setCitiesService={setCitiesSupervisor}
            />
            <Grid item xs={12} md={6}>
              <CustomInput
                label="Teléfono / Celular"
                name="cellphoneNumberSupervisor"
                control={control}
                error={errors}
                rule={Rules.telCelWithoutRequired}
                styleFromHelperText={{ style: { marginRight: 10 } }}
                inputProps={{
                  maxLength: 10,
                  Rules: Rules.telCel,
                }}
                classes={classes.typeInput}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                label="Correo Electrónico"
                name="emailSupervisor"
                control={control}
                rule={{
                  ...Rules.email,
                  validate: validateEmail, // personalizated validation
                }}
                error={errors}
                required
                styleFromHelperText={{ style: { marginRight: 10 } }}
                inputProps={{
                  maxLength: 100,
                }}
                classes={classes.typeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Cargo del supervisor"
                name="supervisorPosition"
                control={control}
                error={errors}
                rule={Rules.textoNumerosTildes}
                styleFromHelperText={{ style: { marginRight: 10 } }}
                inputProps={{
                  maxLength: 100,
                }}
                classes={classes.typeInput}
              />
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <>
            <Grid item xs={12} style={{ paddingTop: 5, paddingBottom: 0 }}>
              <h3>Servicios Contratados</h3>
            </Grid>
            <Container component="main" maxWidth="lg">
              <Paper elevation={3} className={classes.paperGroup}>
                <form
                  autoComplete="off"
                >
                  <Grid container spacing={4} style={{ paddingLeft: '60px', paddingRight: '60px' }}>
                    <Grid item xs={12} md={8}>
                      <InputLabel />
                      <CustomSelect
                        label="Productos"
                        name="productService"
                        options={productType}
                        control={controlProducts}
                        rule={Rules.required}
                        required
                        error={errorsProducts}
                        styleFromHelperText={{ style: { marginRight: 10 } }}
                        inputProps={{ onChange: (e) => setProductType(e) }}
                        classes={classes.typeInput}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CustomInput
                        label="Cantidad Productos"
                        name="quantityToAddService"
                        control={controlProducts}
                        error={errorsProducts}
                        required
                        rule={Rules.numerico}
                        styleFromHelperText={{ style: { marginRight: 10 } }}
                        inputProps={{
                          maxLength: 9,
                        }}
                        onValidate={(e) => {
                          if (e.target.value <= 0) {
                            setValueProducts('quantityToAddService', '');
                          } else if (e.target.value > 100000000) {
                            setValueProducts('quantityToAddService', lastValidValueQuantityProducts);
                          } else {
                            setLastValidValueQuantityProducts(e.target.value);
                          }
                        }}
                        classes={classes.typeInput}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel />
                      <CustomSelect
                        label="Tipo vigencia"
                        name="validityService"
                        options={listValidityTypeContracts}
                        control={controlProducts}
                        required
                        rule={Rules.required}
                        error={errorsProducts}
                        styleFromHelperText={{ style: { marginRight: 10 } }}
                        inputProps={{ onChange: (e) => setValidityType(e) }}
                        classes={classes.typeInput}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CustomInput
                        label="Cantidad vigencia"
                        name="validityAmount"
                        control={controlProducts}
                        error={errorsProducts}
                        required
                        rule={Rules.numerico}
                        styleFromHelperText={{ style: { marginRight: 10 } }}
                        inputProps={{
                          maxLength: 5,
                        }}
                        onValidate={(e) => {
                          if (e.target.value <= 0) {
                            setValueProducts('validityAmount', '');
                          }
                          const valueTypeValidity = getValuesProducts('validityService');
                          if (transformedListMaxValidityAmountContracts.length > 0) {
                            const valueMax = transformedListMaxValidityAmountContracts.find((maxValidity) => maxValidity.value === valueTypeValidity)?.amount;
                            if (e.target.value > valueMax) {
                              setValueProducts('validityAmount', '');
                            }
                          }
                        }}
                        classes={classes.typeInput}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CustomInput
                        label="Cupo Reasignación"
                        name="reassignmentQuota"
                        control={controlProducts}
                        error={errorsProducts}
                        required
                        rule={Rules.numerico}
                        styleFromHelperText={{ style: { marginRight: 10 } }}
                        inputProps={{
                          maxLength: 5,
                        }}
                        onValidate={(e) => {
                          if (e.target.value.startsWith('0')) {
                            setValueProducts('reassignmentQuota', '0');
                          }
                          if (e.target.value < 0 || e.target.value > parameterMaxReassignmentQuota) {
                            setValueProducts('reassignmentQuota', '');
                          }
                        }}
                        classes={classes.typeInput}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel />
                      <CustomSelect
                        label="Tipo de dispositivo"
                        name="deviceType"
                        options={deviceTypeOptions}
                        control={controlProducts}
                        rule={Rules.required}
                        required
                        error={errorsProducts}
                        styleFromHelperText={{ style: { marginRight: 10 } }}
                        inputProps={{ onChange: (e) => setDeviceType(e) }}
                        classes={classes.typeInput}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel />
                      <CustomSelect
                        label="Tipo Entrega Credenciales"
                        name="credentialsDeliveryType"
                        options={credentialsOptions}
                        control={controlProducts}
                        rule={Rules.required}
                        required
                        error={errorsProducts}
                        styleFromHelperText={{ style: { marginRight: 10 } }}
                        inputProps={{ onChange: (e) => setCredentialsType(e) }}
                        classes={classes.typeInput}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Box className={classes.submit}>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          size="medium"
                          className={classes.btnSubmit}
                          onClick={handleSubmitProducts(handleAgregarProducto)}
                        >
                          {isEditing ? 'Actualizar producto' : 'Guardar producto'}
                        </Button>
                        {isEditing && (
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          size="medium"
                          className={classes.btnSubmit}
                          onClick={handleCancelEdit}
                        >
                          Cancelar edición
                        </Button>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                      {servicesToAdd && servicesToAdd.length > 0 ? (
                        <Box style={{ minWidth: '500px' }}>
                          <TableContainer component={Paper} style={{ borderRadius: '12px' }} className={classes.scrollBar}>
                            <Table style={{ minWidth: 300, whiteSpace: 'nowrap' }}>
                              <TableHead className={classes.tableHead}>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    Producto
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    Cantidad
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    Tipo de Vigencia
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    Cantidad de Vigencia
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    Cupo Reasignación
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    Tipo dispositivo
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    Tipo entrega
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableCell}
                                  />
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {servicesToAdd.map((service, index) => (
                                  <TableRow key={index}>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: '14px', fontWeight: 'bold', color: '#333', borderBottomColor: index === servicesToAdd.length - 1 ? 'transparent' : '#009BDD',
                                      }}
                                    >
                                      {productType.find((product) => product.value === service.productService)?.label || 'Desconocido'}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: '14px', fontWeight: 'bold', color: '#333', borderBottomColor: index === servicesToAdd.length - 1 ? 'transparent' : '#009BDD',
                                      }}
                                    >
                                      {service.quantityToAddService}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: '14px', fontWeight: 'bold', color: '#333', borderBottomColor: index === servicesToAdd.length - 1 ? 'transparent' : '#009BDD',
                                      }}
                                    >
                                      {listValidityTypeContracts.find((validity) => validity.value === service.validityService)?.label || 'Desconocido'}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: '14px', fontWeight: 'bold', color: '#333', borderBottomColor: index === servicesToAdd.length - 1 ? 'transparent' : '#009BDD',
                                      }}
                                    >
                                      {service.validityAmount}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: '14px', fontWeight: 'bold', color: '#333', borderBottomColor: index === servicesToAdd.length - 1 ? 'transparent' : '#009BDD',
                                      }}
                                    >
                                      {service.reassignmentQuota}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: '14px', fontWeight: 'bold', color: '#333', borderBottomColor: index === servicesToAdd.length - 1 ? 'transparent' : '#009BDD',
                                      }}
                                    >
                                      {listDeviceTypeContracts.find((validity) => validity.value === service.deviceType)?.label || 'Desconocido'}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: '14px', fontWeight: 'bold', color: '#333', borderBottomColor: index === servicesToAdd.length - 1 ? 'transparent' : '#009BDD',
                                      }}
                                    >
                                      {listCredentialDeliveryContracts.find((validity) => validity.value === service.credentialsDeliveryType)?.label || 'Desconocido'}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: '14px', fontWeight: 'bold', color: '#333', borderBottomColor: index === servicesToAdd.length - 1 ? 'transparent' : '#009BDD',
                                      }}
                                    >
                                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <IconButton
                                          onClick={() => handleEditProduct(index)}
                                          style={{ fontSize: '24px', marginRight: '10px', color: selectedIndex === index ? 'rgba(0, 0, 0, 0.26)' : theme.palette.primary.main }}
                                          disabled={selectedIndex === index}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                        <IconButton
                                          onClick={() => handleDeleteProduct(index)}
                                          style={{ fontSize: '24px', marginLeft: '10px', color: theme.palette.primary.main }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      ) : (
                        <Box style={{ fontSize: '15px', fontWeight: 'bold' }}>
                          <p>No hay productos agregados aún.</p>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Container>
          </>
        );
      default:
        return 'Paso no encontrado';
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper elevation={3} className={classes.paper}>
          <Typography color="primary" variant="h4" className={classes.title}>
            Convenios
          </Typography>
          <Container component="main" maxWidth="lg">
            <Paper elevation={3} className={classes.paperGroup}>
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(handleNext)}
              >
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item xs={0} />
                  <Grid item xs={12} style={{ paddingLeft: '46.29px' }}>
                    <Typography align="left" className={classes.titleForm} variant="h5">
                      Nuevo Convenio
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={4} style={{ paddingLeft: '60px', paddingRight: '60px' }}>
                  <Grid container justifyContent="center" style={{ marginBottom: '20px', paddingTop: '10px' }}>
                    <Grid item>
                      <Stepper activeStep={activeStep} alternativeLabel style={{ display: 'flex', justifyContent: 'center' }}>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                  </Grid>
                  {renderStepContent(activeStep)}
                  <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Box className={classes.submit}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        className={classes.btnSubmit}
                        onClick={handleBack}
                        disabled={activeStep === 0}
                      >
                        Volver
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Box className={classes.submit}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="medium"
                        className={classes.btnSubmit}
                        disabled={(!!((activeStep === steps.length - 1 && servicesToAdd.length === 0)) || invalidContract)}
                      >
                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Container>
        </Paper>
      </Container>
      <AlertConfirm
        isOpen={openAlertConfirm}
        tituloModal="Confirmar"
        mensajeModal="¿Esta seguro que quiere guardar este convenio?"
        handleAccept={() => confirmSaveContract()}
        onCancel
        onClose={() => setOpenAlertConfirm(false)}
      />
    </>
  );
};

const mapStateToProps = (props) => ({
  appName: props.general.appName,
  tenant: props.general.tenant,
  statesAndCities: props.general.statesAndCities,
  citiesServGoogleAssistant: props.google.citiesServGoogleAssistant,
  documentTypes: props.general.documentTypes,
  gestorType: props.license.gestorType,
  productType: props.home.productType,
  loading: props.license.loading,
  listPaymentTypeContracts: props.license.listPaymentTypeContracts,
  listDocumentTypeContracts: props.license.listDocumentTypeContracts,
  listValidityTypeContracts: props.license.listValidityTypeContracts,
  listDeviceTypeContracts: props.license.listDeviceTypeContracts,
  listCredentialDeliveryContracts: props.license.listCredentialDeliveryContracts,
  parameterMaxReassignmentQuota: props.license.parameterMaxReassignmentQuota,
  transformedListMaxValidityAmountContracts: props.license.transformedListMaxValidityAmountContracts,
  emailsInBalckList: props.emails.emailsBlackList,
  contractData: props.license.getContractData,
  decodedToken: props.auth.decodedToken,
});

const mapDispatchToProps = {
  getPurchaseOrderParameters: getPurchaseOrderParametersAction,
  saveContract: saveContractAction,
  getManagers: getManagersAction,
  getPackagesContracts: getPackagesContractsAction,
  getAppParametersContracts: getAppParametersContractsAction,
  getBlackListEmails: getBlackListEmailsAction,
  getContractData: getContractDataAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Agreements);
